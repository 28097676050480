import { render, staticRenderFns } from "./TestOptionButton.vue?vue&type=template&id=b52bd2f4&scoped=true"
import script from "./TestOptionButton.vue?vue&type=script&lang=ts"
export * from "./TestOptionButton.vue?vue&type=script&lang=ts"
import style0 from "./TestOptionButton.vue?vue&type=style&index=0&id=b52bd2f4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b52bd2f4",
  null
  
)

export default component.exports